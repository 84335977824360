import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import PolicyStyled from "../components/styles/PolicyStyles"
import ShowHideItemPolicy from "../components/showHideItemPolicy"
import SEO from "../components/seo"

const BiddingProcedures = ({ data, pageContext }) => {
  const {
    biddingProceduresSingleton,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.swapi

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <div className="container">
        <PolicyStyled>
          <TitleStyled textAlign="center" linePosition="center" color="#000000">
            <h2
              dangerouslySetInnerHTML={{
                __html: biddingProceduresSingleton.title,
              }}
            ></h2>
          </TitleStyled>
          <div className="policy__container">
            {biddingProceduresSingleton.content.map((content, index) => {
              return (
                <ShowHideItemPolicy
                  number={index}
                  key={uuid()}
                  title={content.value.heading}
                  description={content.value.description}
                />
              )
            })}
          </div>
        </PolicyStyled>
      </div>
    </Layout>
  )
}

export default BiddingProcedures

export const query = graphql`
  query biddingProceduresPL {
    swapi {
      biddingProceduresSingleton(lang: "pl") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        title
        content {
          value
        }
      }
    }
  }
`
